<table mat-table [dataSource]="dataSource" [ngClass]="{ stickyTableHeader: !noAssets || !loading }">
    <!-- Name Column -->
    <ng-container matColumnDef="display_label">
        <th mat-header-cell *matHeaderCellDef>{{ 'asset.list.table.heading.name' | translate }}</th>
        <td mat-cell *matCellDef="let element">
            <div class="fa-label ellipsis" title="{{ element.displayLabel || element.id }}">
                {{ element.displayLabel || element.id }}
            </div>
            <div class="fa-site ellipsis">
                {{ (element.site || { displayLabel: 'asset.list.table.unavailable' | translate }).displayLabel }}
            </div>
        </td>
    </ng-container>

    <!-- Site Column -->
    <!--    <ng-container matColumnDef="site">-->
    <!--        <th mat-header-cell *matHeaderCellDef>{{ 'asset.list.table.heading.site' | translate }}</th>-->
    <!--        <td mat-cell *matCellDef="let element">-->
    <!--            {{ (element.site || { display_label: 'asset.list.table.unavailable' | translate }).display_label }}-->
    <!--        </td>-->
    <!--    </ng-container>-->

    <!-- Monitors Column -->
    <ng-container matColumnDef="monitors">
        <th mat-header-cell *matHeaderCellDef class="text-center">
            {{ 'asset.list.table.heading.monitors' | translate }}
        </th>
        <td mat-cell *matCellDef="let element" class="text-center">{{ element.monitors.length }}</td>
    </ng-container>

    <!-- Commands Column -->
    <ng-container matColumnDef="commands">
        <th mat-header-cell *matHeaderCellDef class="text-center">
            {{ 'asset.list.table.heading.commands' | translate }}
        </th>
        <td mat-cell *matCellDef="let element" class="text-center">{{ element.commands.length }}</td>
    </ng-container>

    <!-- Includes Column -->
    <ng-container matColumnDef="includes">
        <th mat-header-cell *matHeaderCellDef class="text-center">
            {{ 'asset.list.table.heading.includes' | translate }}
        </th>
        <td mat-cell *matCellDef="let element" class="text-center">{{ element.includes.length }}</td>
    </ng-container>

    <!--  Status Column -->
    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
            <ng-container [ngSwitch]="element.status">
                <div *ngSwitchCase="'ACTIVE'" class="status-icon status-icon-active" title="Active"></div>
                <div *ngSwitchCase="'INACTIVE'" class="status-icon status-icon-inactive" title="Inactive"></div>
            </ng-container>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

    <tr
        mat-row
        *matRowDef="let row; let isEven = even; columns: displayedColumns"
        class="asset-list-row"
        [ngClass]="{ selectedFA: row.id === selectedAsset }"
        (click)="onRowClicked(row.id)"
    ></tr>
</table>
<ng-container *ngIf="noAssets && !loading">
    <div data-automation="list-empty" class="list-empty">
        {{ 'asset.list.none_configured' | translate }}
    </div>
</ng-container>
<div class="spinner-container" *ngIf="loading">
    <mat-spinner mode="indeterminate" diameter="60"></mat-spinner>
</div>
<div class="create-button-container">
    <button
        (click)="routeToCreatePage()"
        id="create-button"
        data-automation="create-button"
        class="icon-btn x-btn-primary"
        color="accent"
    >
        <mat-icon>add</mat-icon>
        <span class="btn-text">{{ 'asset.list.button.create_asset' | translate }}</span>
    </button>
</div>

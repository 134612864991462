<ng-container *ngIf="dialogState === 'DEFAULT'">
    <h1 data-automation="deleteDialogHeader" mat-dialog-title>{{ 'asset.dialog.delete.title' | translate }}</h1>
    <div mat-dialog-content>
        <p data-automation="dialogText">{{ 'asset.dialog.delete.text' | translate }}</p>
    </div>
    <div mat-dialog-actions>
        <!--    <button mat-button (click)="onNoClick()">{{ 'asset.edit.button.cancel' | translate }}</button>-->
        <!--    <button mat-button>{{ 'asset.edit.button.delete' | translate }}</button>-->

        <button
            class="x-btn-secondary dialog-secondary"
            color="gray"
            aria-label="Cancel"
            (click)="onNoClick()"
            data-automation="dialogCancel"
        >
            <span> {{ 'asset.edit.button.cancel' | translate }}</span>
        </button>

        <button
            class="x-btn-primary dialog-primary"
            color="accent"
            aria-label="Delete"
            (click)="handleDelete()"
            data-automation="dialogDelete"
        >
            <span> {{ 'asset.edit.button.delete' | translate }}</span>
        </button>
    </div>
</ng-container>

<ng-container *ngIf="dialogState === 'DELETING'">
    <div mat-dialog-content>
        <div class="dialog-spinner">
            <div class="spinner-container">
                <mat-spinner diameter="24"></mat-spinner>
            </div>
            <span data-automation="dialogText">{{ 'asset.dialog.delete.deleting' | translate }}</span>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="dialogState === 'DELETE_SUCCESS'">
    <div mat-dialog-content>
        <div class="dialog-result">
            <mat-icon>check_circle</mat-icon>
            <span data-automation="dialogText">{{ 'asset.dialog.delete.success' | translate }}</span>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="dialogState === 'DELETE_FAILED'">
    <div mat-dialog-content>
        <div class="dialog-result">
            <mat-icon>warning</mat-icon>
            <span data-automation="dialogText">{{ 'asset.dialog.delete.failed' | translate }}</span>
        </div>
    </div>
</ng-container>
